<template>
  <div>
    <checkout-finish-shipping v-if="CHECKOUT_DATA.shipping_option == 'Versand mit DHL'" />
    <checkout-finish-pickup v-else />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CheckoutFinishPickup from "@/views/checkout/finish/CheckoutFinishPickup";
import CheckoutFinishShipping from "@/views/checkout/finish/CheckoutFinishShipping";

export default {
  name: "CheckoutFinish",
  components: {
    CheckoutFinishPickup,
    CheckoutFinishShipping
  },
  computed: {
    ...mapGetters(['CHECKOUT_DATA']),
  },
  beforeMount() {
    if (this.CHECKOUT_DATA.repair_option_one === null && this.CHECKOUT_DATA.repair_option_two === null) {
      this.$router.push({ name: 'Home' })
    }
  },
}
</script>

<style scoped>

</style>
