<template>
  <div>
    <b-card>
      <div class="col-md-7">
        <h1 class="h4">Vielen Dank für Ihre Reparaturbuchung!</h1>

        <p class="mt-2">
          Wir bearbeiten Ihren Auftrag schnellstmöglich.
        </p>

        <p class="font-weight-bolder mt-2">Auftragsnummer</p>
        <p class="mt-1">
          Ihre Auftragsnummer lautet: <strong>{{ this.CHECKOUT_DATA.orderNumber }}</strong> <br/>
          Bitte geben Sie diese bei eventuellen Rückfragen an.
        </p>

        <p class="font-weight-bolder mt-2">Wie geht es weiter?</p>
        <p>Sie erhalten eine Auftragsbestätigung per E-Mail, in welcher Sie erfahren, ob die Ersatzteile vorrätig sind oder bestellt werden müssen.</p>

        <ul class="pl-1">
          <li>Ist das Ersatzteil vorrätig, können Sie direkt online einen Reparaturtermin buchen.</li>
          <li>Falls das Ersatzteil erst bestellt werden muss, schicken wir Ihnen eine E-Mail sobald dieses eingetroffen ist. Dann können Sie ebenfalls online Ihren Termin vereinbaren.</li>
        </ul>
        <p class="font-weight-bolder mt-2">So lassen Sie uns Ihr Gerät zukommen</p>
        <p>Sie haben bei Ihrem Auftrag die persönliche Abgabe in Stuhr-Seckenhausen ausgewählt. Gerne können Sie uns Ihr Gerät jederzeit, während der Öffnungszeiten, vorbeibringen, sollten Sie dies nicht benötigen, auch, wenn das Ersatzteil noch nicht eingetroffen ist. Andernfalls warten Sie auf unsere E-Mail und buchen dann einen Termin direkt online.</p>
        <p> Wir haben Montags und Dienstags von 09:00 Uhr - 13:00 Uhr, sowie von 14:00 Uhr - 17:00 Uhr geöffnet.
           Donnerstags sind wir zwischen 9:00 Uhr - 13:00 Uhr und 14:00 Uhr bis 18:00 Uhr für Sie da. Am Freitag haben wir von 9:00 Uhr - 16:00 Uhr für Sie geöffnet.</p>
        <p class="font-weight-bolder mt-2">Was passiert nach der Reparatur?</p>
        <p>Sobald Ihre Reparatur abgeschlossen ist, senden wir Ihnen eine E-Mail, dass Ihr Gerät abholbereit ist.</p>
        <p class="font-weight-bolder mt-2">
          Wie wird bezahlt?
        </p>
        <p>
          Die Zahlung kann bei Abholung in bar oder mit EC-/Kreditkarte erfolgen.
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CheckoutFinishPickup",
  computed: {
    ...mapGetters(['CHECKOUT_DATA']),
  },
}
</script>

<style scoped>

</style>
