<template>
  <div>
    <b-card>
      <div class="col-md-7">
        <h1 class="h4">Vielen Dank für Ihre Reparaturbuchung!</h1>

        <p class="mt-2">
          Wir bearbeiten Ihren Auftrag schnellstmöglich.
        </p>

        <p class="font-weight-bolder mt-2">Auftragsnummer</p>
        <p class="mt-1">
          Ihre Auftragsnummer lautet: <strong>{{ this.CHECKOUT_DATA.orderNumber }}</strong> <br/>
          Bitte geben Sie diese bei eventuellen Rückfragen an.
        </p>

        <p class="font-weight-bolder mt-2">Wie geht es weiter?</p>
        <p>Sie erhalten eine Auftragsbestätigung per E-Mail, in welcher Sie erfahren, ob die Ersatzteile vorrätig sind oder bestellt werden müssen.</p>
        <ul class="pl-1">
          <li>Ist das Ersatzteil vorrätig, können Sie Ihr Gerät direkt per Post einsenden.</li>
          <li>Falls das Ersatzteil erst bestellt werden muss, schicken wir Ihnen eine E-Mail sobald dieses eingetroffen ist. Dann können Sie Ihr Gerät versenden.</li>
        </ul>
        <p class="font-weight-bolder mt-2">So lassen Sie uns Ihr Gerät zukommen</p>
        <p>Sie haben bei Ihrem Auftrag Versand mit DHL ausgewählt. In unserer Auftragsbestätigung erhalten das Versandetikett und können uns Ihr Gerät kostenlos zusenden.</p>
        <p>Falls Ihr Ersatzteil nicht vorhanden ist, warten Sie bitte mit dem Versand, bis wir Ihnen eine zweite E-Mail geschickt haben.</p>
        <p class="font-weight-bolder mt-2">Was passiert nach der Reparatur?</p>
        <p>Sobald Ihre Reparatur abgeschlossen ist, senden wir Ihnen Ihr Gerät zurück. Sie erhalten von uns eine E-Mail mit der Sendungsverfolgungsnummer.</p>
        <p class="font-weight-bolder mt-2">
          Wie wird bezahlt?
        </p>
        <p>
          Sie erhalten per E-Mail eine Rechnung und können per Vorkasse oder ganz einfach über PayPal bezahlen.
        </p>
      </div>
    </b-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CheckoutFinishShipping",
  computed: {
    ...mapGetters(['CHECKOUT_DATA']),
  },
}
</script>

<style scoped>

</style>
